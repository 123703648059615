$screen-sm: 480px;
$screen-md: 640px;
$screen-lg: 900px;

@mixin respond-to($media) {
  @if $media == sm {
    @media only screen and (max-width: $screen-sm) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $screen-sm + 1) and (max-width: $screen-md - 1) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $screen-md + 1) and (max-width: $screen-lg - 1) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $screen-lg) { @content; }
  }
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

html {
  background: #333;
  color: #e5e5e5;
  font: 100%/1.5 "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

a {
  color: #aaddff;
}

#sidebar {
  float: left;

  span {
    color: #aaddff;
  }

  .info-section {
    font-size: 14px;
    padding: 20px 0 20px 15px;
  }

  .next {
    position: relative;

    .tactris-block {
      @extend .active;
      -webkit-transition: 200ms ease-out;
      -moz-transition: 200ms ease-out;
      -ms-transition: 200ms ease-out;
      -o-transition: 200ms ease-out;
      transition: 200ms ease-out;
    }
  }

  #next0, #next1 {
    display: inline-block;
    height: 100px;
    margin: 0 0 0 20px;
    width: 100px;

    @include respond-to(md) {
      height: 50px;
      width: 50px;
    }
    @include respond-to(sm) {
      height: 50px;
      width: 50px;
    }
  }

  #actions {
    padding: 20px 15px;
  }
  #actions a, #actions a:active {
    border: solid 1px #aaddff;
    border-radius: 4px;
    display: inline-block;
    float: left;
    margin: 20px 0 0 15px;
    padding: 10px 15px;
    text-decoration: none;
    -webkit-transition: 200ms ease-out;
    -moz-transition: 200ms ease-out;
    -ms-transition: 200ms ease-out;
    -o-transition: 200ms ease-out;
    transition: 200ms ease-out;

  }
  #actions a:hover {
    box-shadow: 0px 0px 15px 0px rgba(150, 200, 255, 0.7);
    -webkit-transition: 0 ease-out;
    -moz-transition: 0 ease-out;
    -ms-transition: 0 ease-out;
    -o-transition: 0 ease-out;
    transition: 0 ease-out;
  }

  #promo {
    clear: both;
    padding: 60px 0 0 30px;

    a {
      display: inline-block;
      padding: 20px 0;
      text-decoration: none;
    }
  }
}

#tactris {
  overflow: hidden;
}

#ads {
  margin: 30px auto;
  max-width: 760px;

  @include respond-to(lg) {
  }
  @include respond-to(md) {
    display: none;
  }
  @include respond-to(sm) {
    display: none;
  }
}

#gamecontent {
  margin: 0 auto;
  max-width: 760px;
  width: 760px;

  @include respond-to(lg) {
    width: 100%;
  }
  @include respond-to(md) {
    width: 100%;
  }
  @include respond-to(sm) {
    width: 100%;
  }
}

#pole {
  float: left;
  position: relative;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  //height: 500px;
  width: 500px;

  @include respond-to(lg) {
    //height: 100%;
    width: 60%;
  }
  @include respond-to(md) {
    //height: 100%;
    width: 60%;
  }
  @include respond-to(sm) {
    //height: 100%;
    width: 100%;
  }
}

.tactris-block {
  cursor: default;
  position: absolute;
  background-color: #0a0a0a;
  z-index: 0;
}

.tactris-block.active {
  box-shadow: 0px 0px 25px 0px rgba(150, 200, 255, 0.7);
  background-color: #aaddff;
  z-index: 100;

  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -ms-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}

.tactris-block.placed {
  box-shadow: inset 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;

  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -ms-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}

#gameover {
  position: absolute;
  //left: 0;
  top: 0;
  height: 300px;
  width: 500px;
  overflow: hidden;
  padding-top: 200px;
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
}
